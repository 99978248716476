<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >

        <validation-observer
          ref="form"
          #default="{invalid}"
        >
          <b-form @submit.prevent>
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">

                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                  <!-- Header: Left Content -->

                  <b-col
                    cols="12"
                    xl="8"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      Sprzedawca:
                    </h6>

                    <strong>  InFakt Sp z o.o.</strong>
                    <p class="mb-0 pb-0">
                      Kącik 4 ,   30-549 Kraków
                    </p>

                    <p class="mb-0 pb-0">
                      NIP: 945-212-16-81
                    </p>
                    <p class="mb-0 pb-0">
                      Kraj: Polska
                    </p>

                    <p class="mt-1pb-0">

                      <b-badge

                        pill
                        variant="success"
                      >
                        VAT
                      </b-badge>
                      Podmiot jest czynnym podatnikiem VAT
                    </p>

                    <!-- Selected Client -->
                    <div
                      v-if="dataForm.client"
                      class="mt-1"
                    >
                      <h6 class="mb-25">
                        {{ dataForm.client.name }}
                      </h6>
                      <b-card-text class="mb-25">
                        {{ dataForm.client.company }}
                      </b-card-text>
                      <b-card-text class="mb-25">
                        {{ dataForm.client.address }}, {{ dataForm.client.country }}
                      </b-card-text>
                      <b-card-text class="mb-25">
                        {{ dataForm.client.contact }}
                      </b-card-text>
                      <b-card-text class="mb-0">
                        {{ dataForm.client.companyEmail }}
                      </b-card-text>
                    </div>
                  </b-col>

                  <!-- Header: Right Content -->
                  <div class="invoice-number-date mt-md-0 mt-2">
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">
                        Invoice
                      </h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="invoice-data-id"
                          v-model="dataForm.id"
                          disabled
                        />
                      </b-input-group>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Date:
                    </span>
                      <flat-pickr
                        v-model="dataForm.issuedDate"
                        class="form-control invoice-edit-input"
                      />
                    </div>
                    <div class="d-flex align-items-center">
                    <span class="title">
                      Due Date:
                    </span>
                      <flat-pickr
                        v-model="dataForm.dueDate"
                        class="form-control invoice-edit-input"
                      />
                    </div>
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->
              <b-card-body
                class="invoice-padding pt-0"
              >

                <b-row class="invoice-spacing">

                  <!-- field: Title -->
                  <b-col
                    cols="12"
                    xl="12"
                    class="mb-2"
                  >
                    <h6 class="mb-2">
                      Tytuł:
                    </h6>
                    <validation-provider
                      #default="{ errors }"
                      name="Title"
                      vid="Title"
                      rules="required"
                    >
                      <b-form-input
                        v-model="dataForm.Title"
                        :disabled="disabledFields"
                        :state="errors.length > 0 ? false:null"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <!-- field: Description -->
                  <b-col
                    cols="12"
                    xl="12"
                    class="mb-2"
                  >
                    <h6 class="mb-2">
                      Opis wyciągu:
                    </h6>
                    <validation-provider
                      #default="{ errors }"
                      name="Description"
                      vid="Description"
                      rules="required"
                    >
                      <b-form-textarea
                        v-model="dataForm.Description"
                        :disabled="disabledFields"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Opis wyciągu..."
                        rows="5"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <!-- field: Bank account number -->
                  <b-col
                    cols="12"
                    xl="12"
                  >
                    <h6 class="mb-2">
                      Numer konta:
                    </h6>
                    <validation-provider
                      #default="{ errors }"
                      name="Bank_account_number"
                      vid="Bank_account_number"
                      rules="required"
                    >
                      <b-form-input
                        v-model="dataForm.Bank_account_number"
                        :disabled="disabledFields"
                        :state="errors.length > 0 ? false:null"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                </b-row>

              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- zalacznik -->

              <b-card-body
                v-if="!preview"
                class="invoice-padding pt-0"
              >
                <h6 class="pb-1">
                  Dodaj załącznik
                </h6>
                <validation-provider
                  #default="{ errors }"
                  name="Attachment"
                  vid="Attachment"
                  :rules="!edit ? 'required' : ''"
                >
                  <b-form-file
                    v-model="dataForm.Attachment"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Przeciągnij i upuść tutaj dokumenty w formacie pdf, skany lub zdjęcia"
                    drop-placeholder="Przeciągnij i upuść tutaj dokumenty w formacie pdf, skany lub zdjęcia"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-card-body>
              <b-card-body
                v-if="dataForm.AttachmentUrl"
                class="invoice-padding pt-0"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  download
                  :href="dataForm.AttachmentUrl"
                  target="_blank"
                  variant="primary"
                  size="sm"
                >
                  Pobierz wyciąg bankowy
                </b-button>
              </b-card-body>

            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >

        <!-- Action Buttons -->
        <b-card>
          <!-- Button: Edit -->
          <b-button
            v-if="preview"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            :to="{ name: 'apps-invoice-edit', params: { id: dataForm.id, type: documentType } }"
          >
            Edytuj
          </b-button>
          <!-- Button: Save -->
          <b-button
            v-else
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="onSubmit()"
          >
            <template v-if="edit">
              Zapisz zmiany
            </template>
            <template v-else>
              Zapisz
            </template>
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import store from '@/store'
import invoiceStoreModule from '@/views/apps/invoice/invoiceStoreModule'
import { onUnmounted, ref } from '@vue/composition-api/dist/vue-composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BFormFile, BCard, BBadge, BCardBody, BButton, BCardText, BForm, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, VBToggle,
} from 'bootstrap-vue'
import { required, min } from '@validations'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormFile,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      documentType: 'bank-statement',
      disabledFields: false,
      required,
      min,
    }
  },
  mounted() {
    if (this.edit || this.preview) {
      this.fetchData()
    }
    if (this.preview) {
      this.disabledFields = true
    }
  },
  created() {
  },
  destroyed() {
  },
  methods: {
    async fetchData(bankStatementType = null) {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      if (bankStatementType !== null) {
        config.params = { type: bankStatementType }
      }
      try {
        await axios.get(`${process.env.VUE_APP_API_URL}Bank-statement/${this.$route.params.id}`, config)
          .then(response => {
            const responseData = response.data
            this.dataForm = {
              id: responseData.id,
              Title: responseData.Title,
              Description: responseData.Description,
              Bank_account_number: responseData.Bank_account_number,
              AttachmentUrl: responseData.Attachment,
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    onSubmit() {
      this.$refs.form.validate().then(success => {
        if (success) {
          const formData = new FormData()
          const token = localStorage.getItem('accessToken')
          const config = { headers: { Authorization: `Bearer ${token}` } }
          const methodForm = this.edit ? 'put' : 'post'
          const formUrl = this.edit ? `${process.env.VUE_APP_API_URL}Bank-statement/${this.dataForm.id}` : `${process.env.VUE_APP_API_URL}Bank-statement`

          formData.append('Title', this.dataForm.Title)
          formData.append('Description', this.dataForm.Description)
          formData.append('Bank_account_number', this.dataForm.Bank_account_number.replace(/\s+/g, ''))
          formData.append('Attachment', this.dataForm.Attachment)

          axios[methodForm](formUrl, formData, config).then(() => {
            Swal.fire({
              title: 'Dokument został wysłany',
              text: 'Dokument został wysłany',
              icon: 'success',
              confirmButtonText: 'OK',
            })
          }, error => {
            if (error.response.data && error.response.data.data) {
              this.$refs.form.setErrors(error.response.data.data)
            }
            Swal.fire({
              title: 'Błąd',
              text: 'Wystąpił błąd podczas wysyłania wyciągu bankowego',
              icon: 'error',
              confirmButtonText: 'OK',
            })
          })
        }
      })
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const dataForm = ref({
      id: null,
      client: null,
      Title: '',
      Description: '',
      Bank_account_number: '',
      Attachment: null,
      AttachmentUrl: null,
    })

    return {
      dataForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
