// import axiosIns from "@/libs/axios"
import i18n from '@/libs/i18n'

export const paymentMethods = [
  'Bank Account',
  'PayPal',
  'UPI Transfer',
]

export const invoicePeriod = [
  i18n.t('invoice.periods.do_not_repeat'),
  i18n.t('invoice.periods.monthly'),
  i18n.t('invoice.periods.quarter'),
]

export const invoiceLanguage = [
  i18n.t('invoice.languages.polish'),
  i18n.t('invoice.languages.english'),
]

export const invoiceStatus = [
  i18n.t('invoice.statuses.issued'),
  i18n.t('invoice.statuses.paid'),
  i18n.t('invoice.statuses.send'),
]

export const paymentCurrency = [
  'PLN',
  'EUR',
]

export const paymentTimePeriod = [
  i18n.t('invoice.term.7_days'),
  i18n.t('invoice.term.14_days'),
  i18n.t('invoice.term.21_days'),
]
