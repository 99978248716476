<template>
  <section class="invoice-add-wrapper">
    <b-form @submit.prevent="onSubmit">
      <b-row class="invoice-add">
        <!-- Col: Left (Invoice Container) -->
        <b-col
            cols="12"
            xl="9"
            md="8"
        >
          <b-card
              no-body
              class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                    <h3 class="text-primary invoice-logo">
                      Vuexy
                    </h3>
                  </div>
                  <b-card-text class="mb-25">
                    Office 149, 450 South Brand Brooklyn
                  </b-card-text>
                  <b-card-text class="mb-25">
                    San Diego County, CA 91905, USA
                  </b-card-text>
                  <b-card-text class="mb-0">
                    +1 (123) 456 7891, +44 (876) 543 2198
                  </b-card-text>
                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Invoice
                    </h4>
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                          id="invoice-data-id"
                          v-model="invoiceData.id"
                          disabled
                      />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Date:
                    </span>
                    <flat-pickr
                        v-model="invoiceData.issuedDate"
                        class="form-control invoice-edit-input"
                    />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title">
                      Due Date:
                    </span>
                    <flat-pickr
                        v-model="invoiceData.dueDate"
                        class="form-control invoice-edit-input"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <b-card-body
                class="invoice-padding pt-0"
            >

              <b-row class="invoice-spacing">

                <!-- waluta -->
                <b-col
                    cols="12"
                    xl="3"
                    class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Waluta:
                  </h6>
                  <v-select
                      :options="paymentCurrency"
                  />
                </b-col>

                <!-- termin płatności -->
                <b-col
                    cols="12"
                    xl="3"
                    class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Sposób płatności
                  </h6>
                  <v-select
                      v-model="invoiceData.paymentMethod"
                      :options="paymentMethods"
                  />
                </b-col>

              </b-row>

              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                    cols="12"
                    xl="6"
                    class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Klient:
                  </h6>

                  <!-- Select Client -->
                  <v-select
                      v-model="invoiceData.client"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="clients"
                      label="company"
                      input-id="invoice-data-client"
                      :clearable="false"
                  >
                    <template #list-header>
                      <li
                          v-b-toggle.sidebar-invoice-add-new-customer
                          class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon
                            icon="PlusIcon"
                            size="16"
                        />
                        <span class="align-middle ml-25">Add New Customer</span>
                      </li>
                    </template>
                  </v-select>

                  <!-- Selected Client -->
                  <div
                      v-if="invoiceData.client"
                      class="mt-1"
                  >
                    <h6 class="mb-25">
                      {{ invoiceData.client.name }}
                    </h6>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.company }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.address }}, {{ invoiceData.client.country }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.contact }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ invoiceData.client.companyEmail }}
                    </b-card-text>
                  </div>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                    xl="6"
                    cols="12"
                    class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                >
                  <div>
                    <h6 class="mb-2">
                      Payment Details:
                    </h6>
                    <table>
                      <tbody>
                      <tr>
                        <td class="pr-1">
                          Total Due:
                        </td>
                        <td><span class="font-weight-bold">$12,110.55</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Bank name:
                        </td>
                        <td>American Bank</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Country:
                        </td>
                        <td>United States</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          IBAN:
                        </td>
                        <td>ETD95476213874685</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          SWIFT code:
                        </td>
                        <td>BR91905</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col
                    cols="12"
                    md="4"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                >
                  <label
                      for="invoice-data-sales-person"
                      class="text-nowrap mr-50"
                  >Name:</label>
                  <b-form-input
                      id="invoice-data-name"
                      v-model="invoiceData.name"
                      placeholder="Name"
                  />
                </b-col>
                <b-col
                    cols="12"
                    md="4"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                >
                  <label
                      for="invoice-data-sales-person"
                      class="text-nowrap mr-50"
                  >Kwota netto:</label>
                  <b-form-input
                      id="invoice-data-netto"
                      v-model="invoiceData.netto"
                      placeholder="0.00"
                  />
                </b-col>
                <b-col
                    cols="12"
                    md="4"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                >
                  <label
                      for="invoice-data-sales-person"
                      class="text-nowrap mr-50"
                  >Vat:</label>
                  <b-form-input
                      id="invoice-data-vat"
                      v-model="invoiceData.vat"
                      placeholder="0"
                  />
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col
                    cols="12"
                    md="6"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                >
                  <label
                      for="invoice-data-sales-person"
                      class="text-nowrap mr-50"
                  >Sales Person:</label>
                  <b-form-input
                      id="invoice-data-sales-person"
                      v-model="invoiceData.salesPerson"
                      placeholder="Edward Crowley"
                  />
                </b-col>

                <!-- Col: Total -->
                <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        $1800
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Discount:
                      </p>
                      <p class="invoice-total-amount">
                        $28
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Tax:
                      </p>
                      <p class="invoice-total-amount">
                        21%
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        $1690
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <b-form-textarea v-model="invoiceData.note" />
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
        >

          <!-- Action Buttons -->
          <b-card>

            <!-- Button: Send Invoice -->
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-75"
                block
                disabled
            >
              Send Invoice
            </b-button>

            <!-- Button: DOwnload -->
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mb-75"
                block
            >
              Preview
            </b-button>

            <!-- Button: Print -->
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                type="submit"
                block
            >
              Save
            </b-button>
          </b-card>

          <!-- Status-->

          <b-form-group
              label="Status"
              label-for="payment-method"
          >
            <v-select
                v-model="invoiceData.invoiceStatus"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="invoiceStatus"
                input-id="payment-method"
                class="payment-selector"
                :clearable="false"
            />
          </b-form-group>

          <!-- Język -->

          <b-form-group
              label="Język"
              label-for="payment-method"
          >
            <v-select
                v-model="invoiceData.invoiceLanguage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="invoiceLanguage"
                input-id="payment-method"
                class="payment-selector"
                :clearable="false"
            />
          </b-form-group>

          <!-- Wystawiaj cyklicznie co :  -->

          <b-form-group
              label="Wysatwiaj cyklicznie co :"
              label-for="payment-period"
          >
            <v-select
                v-model="invoiceData.invoicePeriod"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="invoicePeriod"
                input-id="invoicePeriod"
                class="payment-selector"
                :clearable="false"
            />
          </b-form-group>

          <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->

          <!-- Client Notes -->
          <div class="d-flex justify-content-between align-items-center my-1">
            <label for="clientNotes">Client Notes</label>
            <b-form-checkbox
                id="clientNotes"
                :checked="false"
                switch
            />
          </div>

          <!-- Automatyczna windykacja -->
          <div class="d-flex justify-content-between align-items-center">
            <label for="patymentTerms">Automatyczna windykacja</label>
            <b-form-checkbox
                id="patymentTerms"
                :checked="true"
                switch
            />
          </div>

          <!-- Faktura do paragonu -->
          <div class="d-flex justify-content-between align-items-center mt-1">
            <label for="">Faktura do paragonu</label>
            <b-form-checkbox
                id=""
                :checked="true"
                switch
            />
          </div>

          <!-- Mechanizm podzielonej płatności -->
          <div class="d-flex justify-content-between align-items-center mt-1">
            <label for="">Mechanizm podzielonej płatności</label>
            <b-form-checkbox
                id=""
                :checked="false"
                switch
            />
          </div>

          </div>
        </b-col>
      </b-row>
    </b-form>
    <invoice-sidebar-add-new-customer />
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import invoiceStoreModule from '../invoiceStoreModule'
import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    InvoiceSidebarAddNewCustomer,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        if(typeof this.$refs.form !== 'undefined')
          this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {


    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const clients = ref([])
    store.dispatch('app-invoice/fetchClients')
        .then(response => { clients.value = response.data })

    const itemFormBlankItem = {
      item: null,
      cost: 0,
      gross: 0,
      cost_vat: 0,
      qty: 0,
      description: '',
      vat: 0,
    }

    const invoiceData = ref({
      id: 5037,
      client: null,

      name: '',
      netto: 0.00,
      vat: 0,
      note: 'It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!',
      paymentMethod: null,
    })

    const itemsOptions = [
      {
        itemTitle: 'App Design',
        cost: 24,
        qty: 1,
        description: 'Designed UI kit & app pages.',
      },
      {
        itemTitle: 'App Customization',
        cost: 26,
        qty: 1,
        description: 'Customization & Bug Fixes.',
      },
      {
        itemTitle: 'ABC Template',
        cost: 28,
        qty: 1,
        description: 'Bootstrap 4 admin template.',
      },
      {
        itemTitle: 'App Development',
        cost: 32,
        qty: 1,
        description: 'Native App Development.',
      },
    ]

    const paymentMethods = [
      'Bank Account',
      'PayPal',
      'UPI Transfer',
    ]

    const paymentCurrency = [
      'PLN',
      'EUR',

    ]

    const paymentTimePeriod = [
      '7 dni',
      '14 dni',
      '30 dni',
    ]

    const invoiceStatus = [
      'Wystawiona',
      'Opłacona',
      'Wysłana',
    ]

    const invoiceLanguage = [
      'Polski',
      'Angielski',
    ]

    const invoicePeriod = [
      'Nie ponawiaj',
      'Miesiac',
      'Kwartal',
    ]

    const onSubmit = () => {
      const data = {
        'seller': {
          'name': invoiceData.value.client.name,
          'adres': invoiceData.value.client.address,
          'nip': invoiceData.value.client.nip
        },
        'customer': {
          'name': 'Mariusz',
          'adres': 'Office 149, 450 South Brand Brooklyn',
          'nip': '1234567890'
        },
        'advance_payment': {
          'name': invoiceData.value.name,
          'netto': parseFloat(invoiceData.value.netto),
          'vat': parseInt(invoiceData.value.vat)
        },
        'account_number': 'ETD95476213874685',
        'payment_method': 'Przelew',
        /*'payment_method': invoiceData.value.paymentMethod,*/
        'type': 'Advance_Payment'
      };
      axios.post(`${process.env.VUE_APP_API_URL}Invoice`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(() => {
        Swal.fire(
            'Pomyślnie dodano Fakturę',
            '',
            'success',
        )
      }).catch(error => {
        Swal.fire(
            'Coś poszło nie tak!',
            '',
            'error',
        )
      })
    }

    return {
      invoiceData,
      onSubmit,
      clients,
      itemsOptions,
      itemFormBlankItem,
      paymentMethods,
      paymentCurrency,
      paymentTimePeriod,
      invoiceStatus,
      invoiceLanguage,
      invoicePeriod,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
